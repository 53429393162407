import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Play, Pause, Volume2, VolumeX, ChevronRight, ChevronLeft, HelpCircle, RefreshCw } from 'lucide-react';
import Button from './components/ui/Button';
import Card, { CardHeader, CardContent } from './components/ui/Card';
import { OPENAI_API_KEY } from './config';

const MathProblemSolver = () => {
  const [problem, setProblem] = useState('');
  const [step, setStep] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [solution, setSolution] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showGuide, setShowGuide] = useState(true);
  const synth = window.speechSynthesis;
  const utteranceRef = useRef(null);

  // Function to call the GPT API
  const callChatGPT = async (prompt) => {
    try {
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${OPENAI_API_KEY}`
        },
        body: JSON.stringify({
          model: "gpt-3.5-turbo",
          messages: [
            {
              role: "system",
              content: "Tu es un enseignant de CM2 au Sénégal. Explique les problèmes mathématiques étape par étape, avec des formules et des explications claires, sans utiliser de titres, astérisques ou tirets."
            },
            {
              role: "user",
              content: prompt
            }
          ]
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Erreur API: ${errorData.error?.message || response.statusText}`);
      }

      const data = await response.json();
      return data.choices[0].message.content;
    } catch (error) {
      console.error("Erreur lors de l'appel à l'API:", error);
      throw error;
    }
  };

  // Function to clean up the mathematical signs for better readability
  const cleanTextForSpeech = (text) => {
    return text
      .replace(/\*/g, ' multiplié par ')
      .replace(/\//g, ' divisé par ')
      .replace(/\+/g, ' plus ')
      .replace(/=/g, ' égal ')
      .replace(/\bx\b/g, ' multiplié par ') // replaces multiplication symbol
      .replace(/F\b/g, ' francs ')
      .replace(/m\b/g, ' mètres ')
      .replace(/cm\b/g, ' centimètres ')
      .replace(/kg\b/g, ' kilogrammes ')
      .replace(/(\d+)\/(\d+)/g, '$1 sur $2')
      .replace(/(\d+)\^(\d+)/g, '$1 puissance $2')
      .replace(/√(\d+)/g, 'racine carrée de $1')
      .replace(/π/g, 'pi')
      .replace(/(\d+),(\d+)/g, '$1 virgule $2')
      .replace(/\n/g, '. ')
      .replace(/\s+/g, ' '); // cleans up multiple spaces
  };

  // Function to call ChatGPT and process the response
  const solveProblem = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const prompt = `Résous ce problème de mathématiques pour un élève de CM2 au Sénégal :
      ${problem}.
      Donne les explications étape par étape comme un maître de CM2 au Sénégal, en expliquant chaque calcul et en fournissant une formule encadrée.`;

      const response = await callChatGPT(prompt);
      const steps = response.split(/\d°\)/g).filter(step => step.trim() !== ''); // Splits steps by number

      const formattedSolution = steps.map((step, index) => ({
        id: index + 1,
        content: step.trim().replace(/[*-]/g, ''), // Removes asterisks and dashes
        formula: getFormulaForStep(index + 1), // Returns the corresponding formula
        explanation: getExplanationForStep(index + 1) // Detailed explanation
      }));

      setSolution(formattedSolution);
      setStep(0);
      setShowGuide(false);
    } catch (error) {
      console.error("Erreur lors de la résolution du problème:", error);
      setError(`Une erreur est survenue : ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  // Function to get the formula for each step dynamically
  const getFormulaForStep = (step) => {
    switch (step) {
      case 1:
        return "Nombre d'éléments = Montant total / Prix unitaire";
      case 2:
        return "Prix total = Quantité multipliée par le prix unitaire";
      case 3:
        return "Somme totale = Médicaments + Entretien";
      case 4:
        return "Prix de vente par élément = (Somme totale + Bénéfice) / Nombre d'éléments restants";
      default:
        return "";
    }
  };

  // Function to get the explanation for each step
  const getExplanationForStep = (step) => {
    switch (step) {
      case 1:
        return "Règle : Pour trouver le nombre d'éléments, on divise le montant total par le prix unitaire.";
      case 2:
        return "Règle : On multiplie la quantité par le prix unitaire pour obtenir le prix total.";
      case 3:
        return "Règle : On additionne les coûts des médicaments et de l'entretien pour obtenir la somme totale.";
      case 4:
        return "Règle : Pour fixer le prix de vente, on divise le coût total ajouté au bénéfice par le nombre d'éléments restants.";
      default:
        return "";
    }
  };

  // Function to speak the text with correct reading of symbols
  const speakText = (text) => {
    const cleanedText = cleanTextForSpeech(text);
    if (synth.speaking) {
      synth.cancel();
    }
    utteranceRef.current = new SpeechSynthesisUtterance(cleanedText);
    utteranceRef.current.lang = 'fr-FR';
    utteranceRef.current.rate = 0.9;
    synth.speak(utteranceRef.current);
  };

  useEffect(() => {
    if (solution.length > 0 && isPlaying && !isMuted) {
      speakText(solution[step].content);
    }
    return () => {
      if (synth.speaking) {
        synth.cancel();
      }
    };
  }, [step, solution, isPlaying, isMuted, synth]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (problem.trim()) {
      solveProblem();
    } else {
      setError("Veuillez entrer un problème mathématique.");
    }
  };

  const handleNewProblem = () => {
    setProblem('');
    setSolution([]);
    setStep(0);
    setShowGuide(true);
  };

  const nextStep = () => {
    if (step < solution.length - 1) {
      setStep(step + 1);
    }
  };

  const prevStep = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
    if (!isPlaying && solution.length > 0) {
      speakText(solution[step].content);
    } else if (synth.speaking) {
      synth.cancel();
    }
  };

  const toggleMute = () => {
    setIsMuted(!isMuted);
    if (synth.speaking) {
      synth.cancel();
    }
  };

  const toggleGuide = () => {
    setShowGuide(!showGuide);
  };

  return (
    <div className="max-w-4xl mx-auto p-4 bg-gradient-to-r from-red-600 to-black rounded-lg shadow-lg">
      <h1 className="text-3xl font-bold text-center mb-6 text-white">
        Résolvons les maths ensemble !
      </h1>

      <Button onClick={toggleGuide} className="mb-4 flex items-center text-white">
        <HelpCircle className="mr-2" />
        {showGuide ? "Masquer le guide" : "Afficher le guide"}
      </Button>

      {showGuide && (
        <Card className="mb-6 bg-white text-black">
          <CardHeader className="bg-gray-300 text-black font-semibold">
            Guide d'utilisation
          </CardHeader>
          <CardContent className="text-lg leading-7">
            <ol className="list-decimal list-inside">
              <li className="mb-2">Écrivez votre problème mathématique dans la zone de texte ci-dessous.</li>
              <li className="mb-2">Cliquez sur "Résoudre le problème" pour obtenir une solution étape par étape.</li>
              <li className="mb-2">Utilisez les boutons "Précédent" et "Suivant" pour naviguer entre les étapes.</li>
              <li className="mb-2">Cliquez sur "Lire" pour écouter l'explication de chaque étape.</li>
              <li>N'hésitez pas à poser des questions si quelque chose n'est pas clair !</li>
            </ol>
          </CardContent>
        </Card>
      )}

      <form onSubmit={handleSubmit} className="mb-6">
        <div className="mb-4">
          <label htmlFor="problem" className="block text-lg font-medium text-white mb-2">
            Énoncé du problème
          </label>
          <textarea
            id="problem"
            name="problem"
            rows="4"
            className="w-full p-4 border-2 border-gray-500 rounded bg-gray-100 text-black"
            placeholder="Entrez l'énoncé complet du problème de maths ici"
            value={problem}
            onChange={(e) => setProblem(e.target.value)}
          />
        </div>
        <Button type="submit" className="w-full bg-red-500 text-white p-4 rounded-lg hover:bg-red-600 transition-colors" disabled={isLoading}>
          {isLoading ? 'Résolution en cours...' : 'Résoudre le problème'}
        </Button>
      </form>

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
          <strong className="font-bold">Erreur :</strong>
          <span className="block sm:inline"> {error}</span>
        </div>
      )}

      {solution.length > 0 && (
        <div className="mb-6">
          <h2 className="text-2xl font-bold mb-4 text-white">Résolution du problème</h2>
          <div className="bg-white rounded-lg shadow-md p-6">
            <div className="flex justify-between items-center mb-4">
              <Button onClick={prevStep} disabled={step === 0} className="text-lg text-black">
                <ChevronLeft />
                Précédent
              </Button>
              <span className="font-bold text-black">Étape {step + 1} sur {solution.length}</span>
              <Button onClick={nextStep} disabled={step === solution.length - 1} className="text-lg text-black">
                Suivant
                <ChevronRight />
              </Button>
            </div>
            <Card className="bg-gray-100 p-4 rounded-lg">
              <CardContent className="text-black text-lg leading-7">
                <p className="whitespace-pre-line">{solution[step].content}</p>
                <div className="mt-4 p-4 border rounded-lg bg-gray-200 text-gray-700">
                  <strong>Formule à retenir :</strong> {solution[step].formula}
                </div>
                <p className="mt-4 text-black">{solution[step].explanation}</p>
              </CardContent>
            </Card>
            <div className="flex justify-center mt-4">
              <Button onClick={togglePlay} className="mr-2 text-black">
                {isPlaying ? <Pause className="mr-2" /> : <Play className="mr-2" />}
                {isPlaying ? 'Pause' : 'Lire'}
              </Button>
              <Button onClick={toggleMute} className="text-black">
                {isMuted ? <VolumeX className="mr-2" /> : <Volume2 className="mr-2" />}
                {isMuted ? 'Activer le son' : 'Couper le son'}
              </Button>
            </div>
            <Button onClick={handleNewProblem} className="mt-4 bg-red-500 text-white p-4 rounded-lg">
              Résoudre un autre problème
              <RefreshCw className="ml-2" />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MathProblemSolver;
