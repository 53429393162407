// src/components/ui/Card.js
const Card = ({ children, className }) => {
    return (
      <div className={`bg-white shadow-md rounded-lg p-4 ${className}`}>
        {children}
      </div>
    );
  };
  
  export const CardHeader = ({ children, className }) => {
    return (
      <div className={`font-bold text-lg mb-2 ${className}`}>
        {children}
      </div>
    );
  };
  
  export const CardContent = ({ children, className }) => {
    return (
      <div className={`text-gray-700 ${className}`}>
        {children}
      </div>
    );
  };
  
  export default Card;
  